import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GalleryContainer, HeroContainer } from "@containers";
import { HeaderLayout, FooterLayout, Layout } from "@layouts";
import { getPageItemByScope, getSetting, hasPageItemByScope } from "@definitions/utils";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexBooksQuery {
      allMarkdown: allMarkdownRemark(
        filter: {frontmatter: {scope: {eq: "index-books"}}}
      ) {
        edges {
          node {
            id
            frontmatter {
              author
              categories
              date(formatString: "LLL")
              scope
              slug
              title
            }
            html
          }
        }
      }

      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "index-books"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
  `);

  return (
    <Layout
      title={getSetting(data.page?.pageSettings, "title")}
      className="white-version"
    >
      {(data.header && data.router) && (
        <HeaderLayout
          data={{
            header: data.header,
            router: data.router,
            socials: data.site?.siteMetadata?.socials
          }}
          className="rn-d-none"
        />
      )}
        <main className="main-page-wrapper">
          {hasPageItemByScope(data.page?.items, "hero") && (
            <HeroContainer
              id="hero"
              data={getPageItemByScope(data.page?.items, "hero")}
            />
          )}

          {hasPageItemByScope(data.page?.items, "gallery") && (
            <GalleryContainer
              id="books"
              data={{
                item: getPageItemByScope(data.page?.items, "gallery")
              }}
              isLightbox={false}
            />
          )}
        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
          className="section-separator"
        />
      )}
    </Layout>
  );
}


export default IndexPage;
